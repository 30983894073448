<template>
  <div class="payment" v-if="show">
    <div class="con">
      <div class="bg"></div>
      <div class="button" @click="close">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.payment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .con {
    .bg {
      width: 375px;
      height: 540px;
      background: url(../../../assets/images/base/payment.jpeg) center no-repeat;
      background-size: 100%;
    }

    .button {
      width: 96px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      background-color: #1191F7;
      border-radius: 8px;
      margin: 20px auto;
      cursor: pointer;
    }
  }
}
</style>