<template>
  <div class="report">
    <div class="content">
      <div class="item" v-for="(item, index) in reportList" :key="index">
        <div class="title">{{ item.title }}</div>
        <div class="button" @click="openPayment">10元阅读</div>
      </div>
    </div>

    <Payment ref="Payment" />
  </div>
</template>

<script>
import Payment from './components/payment.vue'
export default {
  components: {
    Payment
  },
  data() {
    return {
      reportList: [
        {
          title: '特斯拉CEO马斯克：下一代AI聊天机器人Grok 3训练需10万块英伟达GPU',
          conetnt: '特斯拉CEO埃隆·马斯克在最近的采访中表示，训练下一代AI聊天机器人Grok 3将需要高达10万块的英伟达H100 GPU芯片。相比之前的Grok 2，其计算能力需求有了大幅提升，预示着AI技术在聊天机器人领域的快速发展。'
        }, {
          title: 'OpenAI进军日本市场，发布日语GPT-4模型',
          conetnt: '为了扩大全球市场影响力，OpenAI近日发布了针对日本市场的日语GPT-4模型，进一步提升了其在多语言处理领域的领先地位。'
        }, {
          title: '京东直播新动向：刘强东AI数字人“采销东哥”将开启直播首秀',
          conetnt: '京东宣布，刘强东的AI数字人“采销东哥”将进行直播首秀，这是AI技术在电商直播领域的一次全新尝试，预计将为消费者带来更加智能、个性化的购物体验。'
        }, {
          title: '第十三届吴文俊人工智能科学技术奖揭晓，70项成果获殊荣',
          conetnt: '在近日举行的第十三届吴文俊人工智能科学技术奖颁奖典礼上，共有70个优秀项目成果获得殊荣，涵盖了AI技术的多个应用领域，展现了我国人工智能领域的蓬勃发展。'
        }, {
          title: '中国第三代自主超导量子计算机“本源悟空”全球访问量突破500万',
          conetnt: '中国自主研发的第三代超导量子计算机“本源悟空”自发布以来，全球访问量已突破500万，标志着我国在量子计算领域取得了重要进展，AI与量子计算的结合也展现出巨大的潜力。'
        }, {
          title: '英特尔AI芯片性能遭质疑，中国市场特供版本性能大幅降低',
          conetnt: '近期有报道指出，英特尔在中国市场特供的AI芯片性能大幅降低，引发了市场对英特尔AI芯片性能的质疑。这一事件也反映出AI芯片市场的激烈竞争和不断变化的技术趋势。'
        }, {
          title: 'TikTok开发AI虚拟网红取代真人，引领社交媒体新潮流',
          conetnt: '为了适应社交媒体发展的新趋势，TikTok正在开发AI虚拟网红，以取代部分真人网红。这一举措有望为社交媒体平台注入新的活力，同时也为AI技术在娱乐领域的应用开辟了新道路。'
        }, {
          title: '苹果iOS 18将推出全新AI功能，全面优化设备端运行效率',
          conetnt: '苹果公司在即将发布的iOS 18中将推出全新的AI功能，旨在优化设备端的运行效率。这些功能包括智能预测、自动优化等，将为用户带来更加流畅、智能的使用体验。'
        }, {
          title: '563支AI队伍角逐2024阿里巴巴全球数学竞赛，展现AI技术新应用',
          conetnt: '在2024年的阿里巴巴全球数学竞赛中，共有563支AI队伍参赛，他们利用AI技术解决数学难题，展现了AI技术在数学领域的新应用和发展潜力。'
        }, {
          title: 'AI助力解决人口问题，中国借自动化推动社会进步',
          conetnt: '面对人口问题带来的挑战，中国正借助自动化和AI技术推动社会进步。通过智能机器人、自动化生产线等技术手段，有效缓解了劳动力短缺的问题，同时提高了生产效率和质量。'
        },
      ]
    }
  },
  methods: {
    openPayment() {
      this.$refs.Payment.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.report {
  min-height: calc(100vh - 220px);
  background: url(../../assets/images/base/bg.jpg) center no-repeat;
  background-size: cover;
  padding: 1px 0;

  .content {
    max-width: 920px;
    margin: 80px auto;
    background: #ffffff;
    border-radius: 16px;
    padding: 60px 40px;

    .item {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeee;

      &:last-child {
        border-bottom: 0px;
      }

      &:hover {
        .title {
          color: #1191F7;
        }
      }

      .title {
        flex: 1;
        line-height: 20px;
        cursor: pointer;
      }

      .button {
        width: 96px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        background-color: #1191F7;
        border-radius: 8px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>