<template>
  <div class="contact">
    <div class="content">
      <div class="titbox">
        <div class="title">7*24小时在线咨询服务</div>
        <div class="subtit">专业团队1对1服务，为每一个合作伙伴量身定制服务，提供解决方案。</div>
      </div>
      <div class="box">
        <div class="con">
          <div class="item">
            <div class="icon name"></div>
            <input class="input" type="text" placeholder="请输入姓名">
          </div>
          <div class="item">
            <div class="icon hangye"></div>
            <input class="input" type="text" placeholder="请输入行业">
          </div>
          <div class="item">
            <div class="icon dizhi"></div>
            <input class="input" type="text" placeholder="请输入地址">
          </div>
          <div class="item">
            <div class="icon phone"></div>
            <input class="input" type="text" placeholder="请输入电话">
          </div>
        </div>

        <div class="button">立即申请</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.contact {
  min-height: calc(100vh - 220px);
  background: url(../../assets/images/base/bg.jpg) center no-repeat;
  background-size: cover;
  padding: 1px 0;

  .content {
    max-width: 1000px;
    margin: 80px auto;

    .titbox {
      margin-bottom: 40px;

      .title {
        text-align: center;
        color: #ffffff;
        font-size: 46px;
      }

      .subtit {
        text-align: center;
        color: #ffffff;
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .box {
      background: #ffffff;
      border-radius: 16px;
      padding: 60px 0px;
      max-width: 1000px;
      margin: 0 auto;

      .con {
        display: flex;
        width: calc(100% - 100px);
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0 50px 50px;

        .item {
          width: calc(50% - 80px);
          margin: 20px;
          padding: 10px 0;
          border: 1px solid #dbdbdb;
          border-radius: 35px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .icon {
            width: 60px;
            height: 30px;

            &.name {
              background: url(../../assets/images/base/icon_name.svg) center no-repeat;
              background-size: 26px;
            }

            &.hangye {
              background: url(../../assets/images/base/icon_hangye.svg) center no-repeat;
              background-size: 26px;
            }

            &.dizhi {
              background: url(../../assets/images/base/icon_dizhi.svg) center no-repeat;
              background-size: 26px;
            }

            &.phone {
              background: url(../../assets/images/base/icon_phone.svg) center no-repeat;
              background-size: 26px;
            }
          }

          .input {
            width: calc(100% - 70px);
            height: 30px;
            border: 0;
            margin: 0;
            padding: 0;
          }
        }
      }

      .button {
        width: 120px;
        height: 48px;
        line-height: 48px;
        color: #fff;
        text-align: center;
        border: 2px solid #4676fa;
        background-color: #4676fa;
        margin: 0 auto;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>