<template>
  <div class="about">
    <div class="head">
      <div class="content">
        <div>
          <div class="title">数字商业全域应用服务商</div>
          <div class="title">更专业 更懂行的互联网商业伙伴</div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="left">
        <div class="title">渡川介绍</div>
        <div class="texts">
          <div class="text">北京渡川科技有限公司是一支拥有丰富企业服务经验的高新技术企业，产品服务覆盖广泛，涉及商城、餐饮、美业、物业等30多个领域！</div>
          <div class="text">
            我们致力于通过专业的互联网产品应用、社媒运营、营销推广、精准投放，打造全行业场景全链路的SaaS应用服务。帮助企业和商家加速产业发展和转型升级，成为企业信赖的互联网商业伙伴，共同开拓数字商业新时代。</div>
        </div>
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.about {
  .head {
    height: 300px;
    background: url(../../assets/images/about/about_head_bg.png) center no-repeat;
    background-size: cover;

    .content {
      width: 1200px;
      height: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        text-align: center;
        font-size: 40px;
        line-height: 60px;
      }
    }
  }

  .box {
    width: 1200px;
    margin: 80px auto;
    display: flex;
    justify-content: space-between;

    .left {
      margin-right: 140px;
      flex: 1;

      .title {
        font-size: 26px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }

      .texts {
        margin-top: 20px;

        .text {
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          text-indent: 2em;
          margin-bottom: 10px;
        }
      }
    }

    .right {
      width: 460px;
      height: 271px;
      background: url(../../assets/images/about/about_my.png) center no-repeat;
      background-size: 100%;
    }
  }
}
</style>